import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1008.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,1008.000000) scale(0.100000,-0.100000)">


<path d="M4790 6657 c-129 -77 -303 -228 -402 -347 -283 -338 -415 -752 -379
-1189 33 -405 208 -767 507 -1050 332 -314 793 -477 1247 -440 145 12 262 36
414 86 l111 36 74 -21 c40 -12 91 -22 114 -22 66 0 189 36 288 85 165 80 403
244 424 290 50 110 -77 331 -295 514 -99 84 -203 141 -255 141 -41 0 -139 -72
-254 -185 -97 -96 -131 -140 -173 -222 -41 -82 -60 -170 -58 -264 3 -98 -8
-110 -127 -148 -167 -54 -246 -66 -426 -66 -225 0 -392 37 -588 130 -224 106
-445 294 -580 493 -370 543 -367 1287 8 1817 77 109 229 263 329 333 75 52 86
69 21 29z m1885 -2070 c0 -33 -99 -146 -265 -300 -133 -123 -146 -132 -165
-117 -27 22 -16 44 50 100 35 30 128 121 207 203 82 84 149 146 158 145 9 -2
15 -14 15 -31z m175 -93 c0 -33 -216 -252 -369 -373 -114 -90 -142 -107 -165
-98 -32 12 -16 44 42 88 102 76 225 185 337 298 116 117 155 139 155 85z m138
-129 c5 -37 -219 -237 -453 -404 -104 -74 -137 -85 -143 -44 -3 17 8 30 44 55
163 113 318 239 464 376 48 45 83 52 88 17z m112 -150 c19 -23 4 -47 -77 -116
-146 -122 -260 -199 -416 -276 -102 -51 -137 -55 -137 -18 0 21 13 31 93 71
143 72 333 196 408 266 51 47 100 88 108 88 5 0 14 -7 21 -15z"/>
<path d="M5175 6542 c3 -11 17 -63 30 -117 63 -248 0 -465 -163 -562 -40 -23
-58 -27 -122 -27 -58 0 -85 5 -117 22 -137 71 -194 204 -160 368 9 40 25 91
37 115 l21 44 -51 -55 c-69 -73 -151 -198 -195 -295 -20 -44 -41 -89 -47 -99
-8 -16 -7 -18 7 -12 16 6 16 4 4 -11 -8 -9 -9 -14 -3 -10 7 4 17 0 24 -8 7 -8
19 -15 26 -15 8 0 14 -7 14 -16 0 -9 21 -36 47 -60 25 -24 43 -44 38 -44 -4 0
15 -21 43 -47 27 -27 58 -61 68 -78 11 -16 53 -65 94 -108 41 -44 95 -100 120
-126 25 -26 55 -61 67 -77 30 -40 62 -74 71 -74 4 0 13 -13 20 -30 8 -16 19
-30 26 -30 6 0 38 14 71 31 225 115 302 395 219 797 -26 128 -172 542 -191
542 -2 0 -2 -8 2 -18z"/>
<path d="M6841 6345 c12 -22 30 -51 40 -65 31 -46 106 -204 137 -290 66 -182
102 -381 102 -564 l0 -115 -46 -23 c-88 -43 -137 -124 -153 -249 -12 -91 -25
-339 -18 -339 12 0 90 149 128 245 58 145 107 187 155 134 38 -42 9 -214 -66
-393 -17 -39 -28 -71 -26 -74 6 -5 98 108 147 180 23 33 57 95 77 137 55 120
46 227 -28 330 -15 22 -20 45 -20 93 -1 314 -174 738 -400 978 -50 54 -51 54
-29 15z"/>
<path d="M6292 6340 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6106 6234 c4 -33 8 -126 7 -209 0 -161 -18 -285 -55 -389 l-22 -59
-22 27 c-25 28 -35 33 -31 14 1 -8 -10 -13 -30 -14 -18 -1 -30 3 -27 8 3 4 0
8 -5 8 -6 0 -11 12 -12 28 0 27 -1 27 -11 5 -13 -27 -40 -32 -31 -5 3 9 11 23
17 31 8 11 8 13 -1 8 -7 -4 -13 0 -16 10 -4 14 -5 12 -6 -5 0 -12 -5 -30 -10
-40 -6 -9 -27 -55 -47 -102 -33 -75 -85 -161 -121 -198 -7 -7 -13 -17 -13 -21
0 -13 -177 -188 -238 -234 -55 -43 -94 -67 -107 -67 -3 0 37 48 90 106 118
131 170 204 229 320 46 92 102 263 93 286 -3 7 -8 -6 -12 -30 -9 -50 -25 -65
-26 -25 -1 17 -3 21 -6 11 -2 -10 -8 -18 -13 -18 -5 0 -7 7 -4 15 9 22 -45 20
-63 -2 -10 -14 -13 -14 -11 -3 2 11 -8 16 -40 18 -36 3 -45 8 -53 30 l-11 27
-13 -25 -13 -25 -10 25 c-5 14 -12 32 -15 40 -3 8 -6 -31 -6 -87 -1 -56 -8
-134 -16 -173 -71 -338 -405 -485 -734 -323 -199 99 -319 292 -322 521 l-2 97
-23 -74 c-48 -155 -56 -433 -18 -632 21 -110 83 -297 129 -389 84 -168 206
-327 332 -436 100 -84 145 -115 254 -170 192 -96 359 -130 588 -120 80 3 161
9 179 12 l32 6 -64 68 c-79 84 -106 148 -106 253 0 158 57 281 272 589 263
378 370 642 399 981 9 95 7 143 -6 249 -8 73 -17 134 -20 136 -8 9 -26 -11
-21 -24 7 -19 -12 -28 -29 -14 -20 17 -125 19 -125 3 0 -7 -4 -13 -10 -13 -5
0 -10 9 -10 19 0 11 -5 23 -11 27 -8 4 -8 -11 -3 -52z m-241 -624 c-3 -5 -12
-10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m45 0 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M6497 5915 c-4 -22 -7 -55 -7 -73 0 -55 -37 -219 -75 -337 -69 -211
-180 -415 -381 -698 -64 -89 -127 -184 -141 -212 l-26 -50 62 58 c71 65 171
142 316 243 226 156 349 320 450 597 53 147 94 385 75 432 -7 17 -9 17 -9 3
-1 -22 -21 -24 -21 -3 0 11 -13 15 -53 15 -28 0 -68 4 -87 10 -25 7 -36 7 -42
-3 -5 -8 -8 -6 -9 8 -1 14 -4 10 -9 -10 l-8 -30 -1 28 c0 15 -5 27 -10 27 -5
0 -11 8 -13 18 -2 9 -7 -1 -11 -23z"/>
<path d="M4391 5884 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4376 5845 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M5680 5741 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6001 5664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7341 2891 c-16 -10 -102 -241 -95 -253 3 -4 1 -8 -4 -8 -14 0 -45
-87 -36 -101 4 -7 3 -9 -3 -6 -11 7 -56 -110 -47 -124 3 -5 1 -9 -4 -9 -5 0
-12 -8 -15 -17 -32 -96 -95 -228 -105 -218 -6 6 -32 -14 -32 -25 0 -3 50 -8
110 -9 91 -2 110 0 110 12 0 8 -13 20 -29 25 -17 6 -31 20 -35 35 -6 25 25
162 41 178 7 7 186 13 269 9 14 0 44 -104 47 -167 2 -28 1 -51 -2 -50 -11 3
-71 -26 -71 -34 0 -13 237 -11 245 1 3 6 0 10 -9 10 -18 0 -49 51 -66 105 -6
22 -14 47 -17 55 -3 8 -7 20 -8 25 -8 35 -68 234 -75 250 -5 11 -25 72 -44
135 -42 141 -54 172 -69 182 -16 10 -39 10 -56 -1z m52 -218 c20 -65 39 -135
43 -156 4 -26 14 -41 28 -47 11 -5 15 -9 7 -9 -7 -1 -11 -7 -7 -16 3 -8 2 -15
-2 -15 -19 -1 -239 -1 -252 0 -11 0 -12 2 -3 8 7 4 33 66 58 137 66 188 77
215 85 215 4 0 23 -53 43 -117z m24 65 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m10 -110 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M8631 2862 c-8 -22 -10 -46 -6 -52 5 -8 4 -11 -2 -7 -14 9 -65 -117
-56 -140 3 -10 2 -14 -2 -10 -12 10 -47 -81 -39 -96 4 -7 3 -8 -1 -4 -5 4 -28
-45 -52 -110 -67 -182 -104 -262 -134 -287 -15 -13 -25 -27 -22 -30 11 -10
213 -7 213 4 0 5 -13 16 -29 25 -16 8 -32 24 -36 35 -5 15 29 174 40 188 4 4
275 5 280 0 2 -2 13 -40 25 -84 23 -92 18 -121 -24 -143 -14 -8 -26 -18 -26
-22 0 -4 54 -7 120 -7 66 0 120 4 120 8 0 5 -13 18 -29 29 -22 16 -35 41 -56
108 -90 286 -157 502 -178 578 -14 48 -15 50 -54 53 -38 3 -40 2 -52 -36z m50
-117 c11 -36 15 -42 21 -28 5 12 6 8 3 -11 -5 -25 3 -68 24 -121 27 -68 48
-155 38 -156 -10 -1 -205 0 -247 1 -12 0 -12 2 -2 8 16 11 94 232 88 248 -3 7
2 15 10 18 9 3 12 10 9 16 -3 6 1 17 9 26 9 8 16 22 16 30 0 30 18 12 31 -31z
m109 -280 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10
-25z m-343 -247 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m60 -84
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M2856 2881 c-4 -6 3 -15 17 -20 14 -6 28 -15 32 -21 6 -9 8 -249 5
-647 0 -15 -10 -28 -30 -38 -17 -9 -28 -20 -25 -25 7 -12 191 -12 220 -1 20 8
20 8 -1 15 -50 16 -56 36 -56 178 l0 132 78 3 c105 3 169 17 159 34 -3 5 1 6
9 3 20 -8 90 61 103 101 23 72 12 172 -26 217 -45 54 -149 77 -343 78 -88 0
-118 -3 -118 -12 0 -10 -3 -9 -9 1 -6 9 -11 10 -15 2z m72 -36 c-3 -3 -9 2
-12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m281 -19 c56 -29 72 -69 69 -166
-1 -47 1 -92 7 -100 8 -13 7 -13 -8 -1 -11 8 -17 9 -17 2 0 -6 -17 -22 -37
-35 -29 -19 -55 -26 -112 -29 -47 -3 -77 -10 -82 -18 -7 -11 -9 -9 -10 6 -1
18 0 342 1 355 1 13 159 1 189 -14z m32 7 c13 -15 12 -15 -8 -4 -24 12 -29 21
-14 21 5 0 15 -7 22 -17z m84 -31 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3
15 -9 12 -12z"/>
<path d="M3545 2880 c-3 -5 -1 -10 4 -10 6 0 20 -9 31 -20 19 -19 20 -33 20
-350 l0 -329 -30 -16 c-17 -9 -28 -20 -24 -26 4 -6 12 -5 22 3 13 11 14 11 7
-1 -7 -12 18 -13 166 -8 195 6 247 18 302 70 41 40 57 76 57 132 0 81 -37 137
-111 170 -45 21 -49 31 -14 47 13 6 22 15 18 21 -4 6 -1 7 6 2 8 -4 25 7 46
30 78 85 56 220 -43 262 -18 7 -38 11 -44 7 -7 -4 -8 -3 -4 4 5 8 -3 12 -26
12 -18 0 -110 3 -205 5 -109 3 -174 1 -178 -5z m385 -55 c48 -25 64 -59 62
-129 -5 -115 -55 -158 -187 -160 -73 -1 -80 -3 -80 -21 0 -18 7 -20 70 -21 94
0 148 -17 180 -56 25 -30 27 -37 23 -114 -3 -88 -15 -112 -70 -140 -29 -15
-218 -25 -218 -11 0 4 -4 5 -10 2 -7 -4 -7 2 0 19 5 15 9 159 9 333 0 170 0
311 0 314 2 13 192 -1 221 -16z m106 -12 c-3 -3 -12 -1 -19 5 -8 7 -18 8 -22
4 -5 -4 -5 -1 -1 6 5 9 12 9 27 1 12 -6 18 -13 15 -16z m-99 -279 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M4286 2881 c-4 -5 2 -12 13 -16 11 -3 24 -12 30 -19 9 -11 15 -357
11 -653 0 -15 -11 -28 -31 -39 -19 -10 -28 -19 -22 -25 5 -5 116 -8 264 -7
l254 3 3 98 c3 97 2 98 -19 95 -17 -2 -27 -15 -38 -50 -9 -26 -25 -58 -36 -73
-20 -25 -23 -26 -157 -28 -121 -2 -135 0 -123 13 9 11 13 56 14 165 0 83 1
156 1 164 0 11 4 10 14 -4 11 -15 22 -17 73 -12 44 5 65 3 80 -8 11 -8 23 -12
26 -8 4 3 5 -4 2 -16 -2 -11 0 -21 6 -21 5 0 9 -6 9 -14 0 -8 8 -13 18 -11 9
2 20 4 25 4 12 2 8 29 -5 34 -10 4 -10 6 0 6 13 1 17 118 4 137 -14 20 -40 23
-48 6 -4 -9 -7 -20 -6 -23 1 -4 -7 -14 -18 -24 -15 -13 -37 -17 -100 -17 l-81
-1 -1 157 c-1 86 2 159 5 163 4 3 7 1 7 -6 0 -9 27 -12 108 -10 135 4 149 -3
181 -91 6 -18 17 -26 35 -28 l27 -2 -3 83 -3 82 -257 3 c-153 1 -259 -1 -262
-7z m411 -447 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m81 -209
c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-421 -47 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M5135 2883 c-50 -14 -112 -66 -131 -112 -32 -77 -12 -153 58 -216 42
-37 56 -45 77 -40 18 4 22 3 12 -4 -11 -7 -9 -11 8 -20 12 -7 27 -9 34 -5 6 4
9 4 5 -1 -4 -4 19 -25 52 -46 72 -46 95 -90 85 -159 -9 -60 -31 -99 -61 -110
-14 -6 -56 -10 -94 -10 -58 0 -73 4 -98 25 -16 14 -37 42 -45 64 -15 37 -55
63 -58 38 -5 -43 -7 -105 -3 -123 3 -18 15 -23 73 -33 86 -14 221 -15 221 -1
0 6 3 9 8 9 21 -4 42 3 56 17 9 8 16 13 16 9 0 -13 50 53 60 81 40 105 -15
218 -138 280 -20 11 -58 31 -85 47 -27 15 -54 24 -60 21 -7 -4 -9 -4 -5 1 4 4
-4 22 -18 41 -36 47 -42 102 -15 157 17 35 29 46 59 55 20 6 49 8 63 5 15 -2
30 0 33 6 4 6 15 11 24 10 14 0 13 -2 -3 -9 -18 -8 -18 -9 -2 -9 36 -2 74 -39
88 -86 12 -39 17 -45 41 -45 28 0 28 0 27 64 -1 66 -14 90 -49 96 -37 5 -217
8 -235 3z m-78 -170 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z
m320 -335 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m30 -60 c-3
-7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-20 -10 c-3 -7 -5 -2 -5 12
0 14 2 19 5 13 2 -7 2 -19 0 -25z m5 -57 c8 11 9 11 6 1 -4 -16 -22 -18 -25
-2 -1 7 0 6 4 -1 4 -8 8 -7 15 2z m-275 -107 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z"/>
<path d="M5546 2883 c-3 -4 -6 -43 -6 -88 0 -85 8 -105 45 -105 16 0 17 2 5
10 -8 5 -10 10 -4 10 6 0 16 18 23 40 6 22 22 52 35 67 21 24 30 27 80 26 34
-1 56 3 56 9 0 6 3 9 6 6 4 -5 7 -514 4 -665 0 -7 -16 -23 -35 -36 -19 -13
-32 -27 -28 -30 3 -4 13 -1 21 6 13 9 14 9 8 0 -5 -10 14 -13 86 -12 51 0 102
4 113 8 20 8 20 8 -1 15 -12 3 -29 15 -39 26 -15 17 -17 48 -16 327 1 171 -3
314 -8 322 -14 22 9 40 38 30 13 -5 39 -8 57 -7 44 3 69 -22 91 -91 14 -43 23
-55 40 -56 28 -2 36 30 31 125 l-3 65 -296 2 c-164 1 -300 -1 -303 -4z"/>
<path d="M6300 2881 c-12 -8 -10 -12 12 -20 14 -5 29 -15 33 -21 6 -9 8 -249
5 -647 0 -15 -10 -28 -30 -38 -17 -9 -28 -20 -25 -25 9 -14 183 -12 303 4 77
10 107 18 118 32 9 11 13 13 9 7 -12 -25 28 -8 76 32 81 66 117 162 117 318 1
65 -3 107 -9 107 -6 0 -8 4 -4 9 10 17 -30 100 -68 140 -46 49 -133 91 -196
96 -164 12 -328 15 -341 6z m362 -59 c55 -24 93 -63 121 -122 19 -39 22 -63
22 -180 0 -161 -5 -191 -47 -256 -18 -27 -32 -53 -33 -59 0 -5 -9 -9 -18 -7
-10 2 -25 -2 -33 -9 -8 -7 -60 -15 -115 -18 -97 -6 -134 -1 -110 13 8 5 11
104 11 324 -1 174 -1 322 0 327 0 19 153 9 202 -13z m-75 -678 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M7726 2883 c-3 -4 -6 -43 -6 -88 0 -62 4 -86 16 -95 20 -17 35 -1 58
62 24 66 49 83 117 82 93 -3 96 -3 79 -14 -13 -8 -15 -57 -17 -326 -2 -311 -2
-317 -24 -340 -12 -13 -25 -21 -30 -19 -4 3 -10 1 -13 -4 -10 -16 28 -22 133
-20 77 2 101 5 101 16 0 7 -6 13 -14 13 -8 0 -22 10 -30 22 -14 19 -16 69 -16
344 l0 322 48 4 c78 8 101 -11 147 -123 6 -14 18 -24 30 -24 19 0 20 7 20 95
l0 95 -296 2 c-163 1 -299 -1 -303 -4z m551 -125 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
